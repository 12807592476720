import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Link, RouteComponentProps } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { blogs } from '../blogs';
import CodeBlock from "./CodeBlock";

const HomePage = () => {
	return (
		<React.Fragment>
			{Object.keys(blogs).map((key) => {
				const blogPost = blogs[key];
				return (
					<Link key={key} className="card" to={`/blog/${key}`}>
						<div className="flex justify-between mb-2">
							<div className="pill-design">{blogPost.date}</div>
							{/*<div className="pill-design">{blogPost.tag}</div>*/}
						</div>
						<div className="subject">{blogPost.title}</div>
					</Link>
				);
			})}
		</React.Fragment>
	);
};

class RouteInfo {
	postId: string | undefined;
}

const Post = (match: RouteComponentProps<RouteInfo>) => {
	const [ text, setText ] = useState();

	const getBlogContent = async (postId: string) => {
		const response = await fetch(blogs[postId].file);
		const text = await response.text();
		await setText(text);
	};

	useEffect(() => {
		const postId: string | undefined = match.match.params.postId;
		if (postId) {
			getBlogContent(postId);
		}
	}, []);

	let displayContent = null;
	if (text) {
		displayContent = <ReactMarkdown
			source={text}
			renderers={{
				code: CodeBlock,
			}}
			escapeHtml={false}
		/>;
	} else {
		displayContent = (
			<div className="relative w-full mt-12">
				<div className="spinner" />
			</div>
		);
	}

	return <div className="markdown w-full mx-4">{displayContent}</div>;
};

function App() {
	return (
		<div className="w-full bg-gray-100 absolute left-0 top-0 min-h-full">
			<div className={'container mx-auto px-4'}>
				<div className={'flex flex-col items-center w-full'}>
					<Router>
						<div className="name-header">LE VAN TUAN LONG</div>
						<div className="intro">
							Welcome to my page where I write about multiple things such as programming, quant trading,
							machine learning and others...
						</div>
						<div className="flex justify-center font-mono">
							<Link to="/">
								<div className="header-nav">BLOG</div>
							</Link>
							<a href="https://www.linkedin.com/in/levanify" target="_blank">
								<div className="header-nav">LINKEDIN</div>
							</a>
						</div>
						<Route exact path="/" component={HomePage} />
						<Route exact path="/blog/:postId" component={Post} />
					</Router>
				</div>
			</div>
		</div>
	);
}

export default App;
