import React from 'react';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import tsx from 'react-syntax-highlighter/dist/esm/languages/prism/tsx';
import {tomorrow} from "react-syntax-highlighter/dist/esm/styles/prism";


SyntaxHighlighter.registerLanguage('tsx', tsx);

interface Props {
    value: string,
    language?: string,
}

export default class CodeBlock extends React.PureComponent<Props, any> {
    render() {
        const { language, value } = this.props;
        console.log(language);

        return (
            <SyntaxHighlighter
                language={language}
                style={tomorrow}
                showLineNumbers={true}
                customStyle={
                    {display: "flex", overflowWrap: "normal"}
                }
                lineNumberStyle={
                    {color: "gray", borderRight: "1px solid gray", paddingRight: "0.5rem"}
                }
            >
                {value}
            </SyntaxHighlighter>
        );
    }
}
