export const blogs: { [postId: string]: { date: string; tag: string; title: string; file: string } } = {
	'12-14-what-is-flutter': {
		date: '14 Dec 19',
		tag: 'DEV',
		title: 'Advantages of using Flutter and why you might want to learn it',
		file: require('./12-14-what-is-flutter.md')
	},
	'12-10-basic-guide-fetch-function': {
		date: '10 Dec 19',
		tag: 'DEV',
		title: "A basic guide to understanding 'Fetch' in JavaScript with code examples",
		file: require('./12-10-basic-guide-fetch-function.md')
	},
	'12-09-react-router-netlify-page-not-found': {
		date: '09 Dec 19',
		tag: 'DEV',
		title:
			"When using React Router with Netlify, non-root routes give 'Page Not Found' error when user accesses the route directly using URL or refreshes the page",
		file: require('./12-09-react-router-netlify-page-not-found.md')
	},
	'12-08-react-router-basic-starter': {
		date: '08 Dec 19',
		tag: 'DEV',
		title: 'The Basics of React Router: An Introduction',
		file: require('./12-08-react-router-basic-starter.md')
	}
};

// ## TEMPLATE ##

// "name": {
//     date: "date",
//     tag: "DEV",
//     title: "",
//     file: require("./path.md")
// },
